@import "../../../../styles/global";
@import "../../../../styles/font";

.color-picker {
    position: absolute;
    border-radius: 5px;
    top: 50px;
    left: -1px;
    padding: 8px;
    border: 1px solid $dark;
    z-index: 1;

    .row {
        display: flex;
    }

    .option {
        margin: 4px;
        flex-direction: column;
        box-sizing: border-box;
        width: 24px;
        height: 24px;
        border: 12px solid;
        border-radius: 12px;
        transition: .1s;

        &.selected {
            border: 8px solid;
        }

        &:hover {
            border: 8px solid;
        }
    }
}
