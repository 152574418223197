// brand colors
$brand:                 #268EDC;
$darker-brand:          #0A2F45;

// color tones
$info:                  $brand;
$success:               #20BF55;
$caution:               #F2B724;
$error:                 #DB162F;

// greyed tones
$white:                 #fff;

$lightest:              #F8FAFD;
$lighter:               #F0F4F8;
$light:                 #E9EBED;

$grey:                  #D4D9DE;
$dark:                  #A5A9B1;
$darker:                #717881;
$black:                 #000;
