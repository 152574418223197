@import "../../styles/global";
@import "../../styles/font";

.grid-container {
    display: grid;
    grid-template-rows: 72px auto; 
    grid-template-columns: 266px 1fr;
    grid-template-areas: 
        'header header'
        'menu main';
    background-color: $light;
    max-width: 100vw;
    height: 100vh;

    div {
        background-color: $white;
    }

    .header {
        grid-area: header;
        border-bottom: 1px solid $light;

        .logout-button {
            position: absolute;
            right: 20px;
            top: 20px;
        }

        .settings-dropdown {
            position: absolute;
            right: 64px;
            top: 20px;

            .dd-header {
                .dd-header-title {
                    line-height: 34px;
                }
            }
        }
    }
    
    .menu {
        grid-area: menu;
        border-right: 1px solid $light;
    }
    
    .main {
        overflow-y: scroll;
        grid-area: main;
        padding: 26px 40px;
    
        h1 {
            :first-of-type {
                margin: 0;
                font-size: 24px;
                line-height: 32px;
                @include sans;
                color: $darker-brand;
            }
        }
    }
}
