@import "../../../styles/global";
@import "../../../styles/font";

.home {

    .no-dashboard-message {
        @include roboto;
        margin-top: 32px;
        width: 100%;
        text-align: center;
        line-height: 45px;
        font-size: 14px;
        color: $dark;
        font-weight: $regular;
    }

    .filter-container  {
        display: flex;
        margin-top: 22px;

        .filter-item {
            flex-direction: column;
            margin: 0;

            .button {
                padding: 0 20px;
                height: 44px;

                &.button-selected {
                    padding: 0 26px;
                }
            }
        }
    }

    h1 {
        @include sans;
        font-size: 24px;
        line-height: 32px;
        font-weight: $bold;
        color: $darker-brand;
        margin-top: 0px;
        margin-bottom: 26px;
    }

    .group-info-container {
        display: flex;
        margin-top: 28px;

        .info-box {
            margin: 0 5px;
            
            &:first-of-type {
                margin: 0;
                margin-right: 5px;
            }
            &:last-of-type {
                margin: 0;
                margin-left: 5px;
            }
            &:only-of-type{
                margin: 0;
            }
        }
    }
}
