@import "../../../styles/global";
@import "../../../styles/font";

.input-parent {

    .input {
        position: relative;
        display: flex;
        border: 1px $light solid;
        border-radius: 5px;
        height: 42px;
    
        &:hover {
            border: 1px $grey solid;
        }
    
        &.picker {
            input {
                user-select: none;
                cursor: pointer;
            }
    
            cursor: pointer;
        }
    
        &.focussed {
            border: 1px $dark solid;
        }
    
        .icon {
            flex-direction: row;
            flex-grow: 0;
            vertical-align: middle;
            margin-left: 12px;
            margin-top: 12px;
        }
    
        .color {
            box-sizing: border-box;
            width: 20px;
            height: 20px;
            border: 10px solid #F2B724;
            border-radius: 10px;
            margin-top: 11px;
            margin-left: 12px;
        }
    
        input {
            @include roboto;
            
            width: 100%;
            
            font-size: 14px;
            line-height: 16px;
            
            margin: 0 12px;
    
            border-style: none;
            border-color: transparent;
            outline: none;
            border: none;
            box-shadow: none;
            background-color: transparent;
    
            filter: none;
    
            &::-webkit-credentials-auto-fill-button {
                visibility: hidden;
            }
            &::-webkit-credentials-auto-fill-button {
                visibility: hidden;
            }
            &::-ms-clear {
                visibility: hidden;
            }
            &::-ms-reveal {
                visibility: hidden;
            }
        }
    }

    p {
        @include roboto;
        color: $dark;
        font-size: 13px;
        line-height: 20px;
        font-weight: $medium;
        margin-bottom: 4px;
    }
}
