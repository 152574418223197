@import "../../../../styles/global";
@import "../../../../styles/font";

.apps-editor {
    display: grid;

    grid-template-columns: 1fr 1fr;
    grid-template-areas: 'editor extra';

    .editor {

        .tab-header {
            display: flex;
            margin-bottom: 26px;

            h1 {
                @include sans;

                font-size: 24px;
                line-height: 32px;
                font-weight: $bold;
                color: $darker-brand;
                margin: auto;
                flex-grow: 1;
            }

            .button {
                flex-grow: 0;
                width: 88px;
                height: 34px;
                margin-left: 10px;
            }
        }

        .input {
            margin: 0 0 16px;
        }

        .table {
            margin: 16px 0 0;
        }

        .footer {
            display: flex;
            margin: 22px 0;

            div {
                flex-grow: 1;
            }

            .button {
                flex-grow: 0;
                height: 34px;
                width: 88px;
                margin-left: 10px;
            }
        }
    }
}
