@import "../../../../../styles/global";
@import "../../../../../styles/font";

.device-adder {
    width: 550px;
    background-color: $white;
    border-radius: 10px;

    h1 {
        @include sans;
        font-weight: $bold;
        font-size: 14px;
        line-height: 20px;
        color: $darker-brand;
        margin: 0;
        padding: 12px 16px 10px;
        border-bottom: 1px $light solid;
    }

    .table {
        margin: 0 16px;
    }

    .dropdown {
        margin: 10px 16px !important;
        width: 316px !important;
    }

    .footer {
        margin: 20px 16px !important;

        .button {
            height: 34px;
            width: 87px;
        }
    }
}
