@import '../../../styles/global';
@import '../../../styles/font';

.text-area {
    @include roboto;

    p {
        font-size: 13px;
        line-height: 20px;
        color: $dark;
        font-weight: $medium;
        margin: 0;
    }

    textarea {
        margin: 4px 0 0;
        width: 100%;
        box-sizing: border-box;
        border-radius: 5px;
        height: 250px;
        border: 1px $light solid;
        resize: none;

        color: $darker-brand;
        line-height: 24px;
        font-size: 14px;
        font-weight: $regular;
        outline: none;
        padding: 10px 12px;
    }
}
