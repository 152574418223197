@import '../../../styles/global';
@import '../../../styles/font';

.table {
    @include roboto;

    &.small-table {
        background-color: transparent;

        h6 {
            @include roboto;
            background-color: transparent;
            font-size: 13px;
            font-weight: $medium;
            line-height: 20px;
            color: $dark;
            margin: 0 0 5px;
        }

        table {
            background-color: $white;
            border: 1px solid $light;
            
            th {
                padding: 9px 14px;
                border-bottom: 1px solid $light;
              
            }

            td {
                border-bottom: none;
                padding: 10px 14px;
            }

            .tah {
                padding: 9px 0 9px 14px;
            }

            .tai {
                padding: 10px 0 10px 14px;
            }
        }

        .error-message {
            height: 138px;
            border: 1px solid $light;

            div {
                height: 38px;
            }

            p {
                top: 88px;
            }
        }
    }

    h6 {
        @include roboto;
        font-size: 15px;
        font-weight: $medium;
        line-height: 32px;
        color: $darker;
        margin: 28px 0 5px;
    }

    .error-message {
        position: relative;
        height: 148px;

        p {
            position: absolute;
            top: 98px;
            left: 15%;
            width: 70%;
            margin: auto;
            color: $dark;
            font-size: 14px;
            line-height: 24px;
            text-align: center;
            transform: translateY(-50%);
        }

        a {
            color: $dark;
        }

        div {
            background-color: rgba($lighter, .5);
            border-bottom: 1px solid $light;
            height: 48px;
        }
    }

    .link {
        @include roboto;
        font-size: 14px;
        color: $brand;
        font-weight: $regular;
        cursor: pointer;
        margin: 0;
        text-decoration: none;

        &:hover {
            text-decoration: underline;
        }
    }

    .add-action {
        svg {
            g {
                fill: $brand;
            }
            margin-left: 2px;
            margin-right: 6px;
            transform: translateY(1px);
        }

        line-height: 20px;
        font-size: 13px;
        color: $brand;
        font-weight: $medium;
        padding: 7px 0 6px 14px;
        border: 1px solid $light;
        border-top: none;
        cursor: pointer;

        &:hover {
            color: $darker-brand;
            svg {
                g {
                    fill: $darker-brand;
                }
            }
        }
    }

    table {
        background-color: $white;
        font-size: 14px;
        border-collapse: collapse;
        text-align: left;
        width: 100%;
        white-space: nowrap;

        tr {
            transition: .1s;
            &:hover {
                background-color: rgba($lighter, .3);
            }
        }

        .tr-selected {
            transition: .1s;
            background-color: rgba($lighter, .3);
        }

        th {
            background-color: rgba($lighter, .5);
            color: $darker-brand;
            font-weight: $medium;
            line-height: 19px;
            padding: 12px;

            .sorter {
                margin-left: 6px;
                user-select: none;

                svg {
                    cursor: pointer;
                    fill: $dark;
                }
                
                &.desc {
                    svg {
                        #up {
                        fill: $darker-brand;
                        }
                    }
                }

                &.asc {
                    svg {
                        #down {
                            fill: $darker-brand;
                        }
                    }
                }

            }
        }

        .tah {
            background-color: rgba($lighter, .5);
            color: $darker-brand;
            font-weight: $medium;
            line-height: 19px;
            padding: 14px 0 14px 14px;
        }

        td {
            border-bottom: 1px solid $light;
            color: $darker;
            padding: 14px;

            .device-group {
                display: flex;
                background-color: transparent;

                svg {
                    display: inline;
                    margin: auto 8px auto 0px;
                }

                p {
                    margin: 0;
                }
            }
        }

        .tai {
            padding: 14px 0 14px 14px;
            svg { 
                cursor: pointer;

                &:hover {
                    g {
                        fill: $darker;
                    }
                }
            }
        }
    }
}
