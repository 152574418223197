@import "../../../../../styles/global";
@import "../../../../../styles/font";

.app-adder {
    width: 350px;
    background-color: $white;
    border-radius: 10px;

    h1 {
        @include sans;
        font-weight: $bold;
        font-size: 14px;
        line-height: 20px;
        color: $darker-brand;
        margin: 0;
        padding: 12px 16px 10px;
        border-bottom: 1px $light solid;
    }

    .dropdown {
        margin: 10px 16px !important;
        width: 316px !important;
    }

    .close {
        position: absolute;
        top: 16px;
        right: 16px;
        cursor: pointer;

        &:hover {
            g {
                fill: $darker;
            }
        }
    }

    .footer {
        margin: 20px 16px !important;

        .button {
            height: 34px;
            width: 87px;
        }
    }
}
