@import '../../../styles/global';
@import '../../../styles/font';

.switch {
    height: 48px;

    p {
        @include roboto;
        font-weight: $medium;
        font-size: 13px;
        line-height: 20px;
        color: $dark;
        margin: 0 0 4px;
    }

    .holder {
        position: relative;

        input {
            opacity: 0;
            width: 0;
            height: 0;
        }
    
        .slider {
            width: 40px;
            height: 24px;
            position: absolute;
            cursor: pointer;
            top: 0;
            left: 0;
            right: 0;
            bottom: 0;
            background-color: $dark;
            transition: .2s;
        
            &::before {
                position: absolute;
                content: "";
                height: 16px;
                width: 16px;
                left: 4px;
                bottom: 4px;
                background-color: white;
                transition: .2s;
            }
        }
          
        input:checked + .slider {
            background-color: $success;
        }
          
        input:focus + .slider {
            box-shadow: 0 0 1px #2196F3;
        }
          
        input:checked + .slider:before {
            transform: translateX(16px);
        }
        
        .slider.round {
            border-radius: 12px;
        }
          
        .slider.round:before {
            border-radius: 50%;
        }
    }
}
