@import "../../../styles/global";
@import "../../../styles/font";

.release {

    .release-top-bar {
        display: flex;
        margin-bottom: 16px;

        .release-search {
            flex-grow: 1;
        }
    }

    .pagination {
        margin-top: 20px;
    }
    
    .no-release-text {
        @include roboto;
        margin-top: 32px;
        width: 100%;
        text-align: center;
        line-height: 45px;
        font-size: 14px;
        color: $dark;
        font-weight: $regular;
    }

    .tab-header {
        display: flex;

        h1 {
            @include sans;
            font-size: 24px;
            line-height: 32px;
            font-weight: $bold;
            color: $darker-brand;
            margin-top: 0px;
            margin-bottom: 22px;
        }
    }
}
