@import "../../../styles/global";
@import "../../../styles/font";

.datetime-picker {
    position: relative;
    
    h6 {
        @include roboto;
        color: $dark;
        font-size: 13px;
        line-height: 20px;
        font-weight: $medium;
        margin-bottom: 4px;
        margin-top: 13px;
    }



    .datetime-picker-holder {
        position: relative;
        display: flex;
        width: 231px;
        flex-direction: row;
        margin: 0;

        svg {
            position: absolute;
            left: 13px;
            top: 13px;

            &:last-of-type {
                left: 155px;
            }
        }

        .react-datepicker-wrapper {
            &:last-of-type {
                width: 90px !important;
            }
        }

        .react-datepicker-popper {

            .datetime-picker {
                border-radius: 5px;
                border: 1px $dark solid;
            }

            .react-datepicker {
                border-radius: 5px;
                border: 1px $dark solid;
                box-sizing: border-box;
            
                .react-datepicker__time-container  {
                    background-color: transparent;

                    .react-datepicker-time__header {
                        @include roboto;
                        color: $darker-brand;
                        font-size: 14px;
                        font-weight: $medium;
                        border-bottom: none;
                    }

                    .react-datepicker__header--time {
                        border-bottom: none;
                    }

                    .react-datepicker__time {
                        ul {
                            height: 250px;
                        }

                        li {
                            @include roboto;
                            line-height: 30px;
                            height: 30px;
                            font-size: 14px;
                            color: $darker;
                            padding: 0px 10px;

                            &.react-datepicker__time-list-item--selected {
                                color: $darker-brand;
                                background-color: $lightest;
                            }

                            &:hover {
                                background-color: $lightest;
                            }
                        }
                    }
                }

                .react-datepicker__navigation--next {
                    margin-top: 5px;
                }

                .react-datepicker__navigation--previous {
                    margin-top: 5px;
                }

                .react-datepicker__triangle {
                    opacity: 0;
                }

                .react-datepicker__month-container {
                    border: none;
                    background-color: transparent;

                    .react-datepicker__header {
                        border-bottom: none;
                    }

                    .react-datepicker__current-month {
                        @include roboto;
                        color: $darker-brand;
                        font-size: 14px;
                        font-weight: $medium;
                        margin: 6px 0;
                    }

                    .react-datepicker__day-names {
                        div {
                            @include roboto;
                            color: $dark;
                            text-transform: uppercase;
                            font-size: 12px;
                            font-weight: $medium;
                        }
                    }

                    .react-datepicker__month {
                        margin: 0 6px 6px;

                        .react-datepicker__month-text {
                            text-transform: uppercase;
                            font-size: 12px;
                            font-weight: $medium;
                        }

                        .react-datepicker__day {
                            @include roboto;
                            color: $darker-brand;
                            font-size: 14px;
                            border-radius: 18px;
                            margin: 0px;
                            padding: 2.656px;
                            
                            &.react-datepicker__day--disabled {
                                color: $grey;
                            }

                            &.react-datepicker__day--disabled {
                                color: $grey;
                            }

                            &.react-datepicker__day--selected {
                                background-color: $brand;
                                color: $white;
                                font-weight: $medium;
                            }
                        }
                    }
                }
            }
        }

        .react-datepicker-wrapper {
            position: relative;
            flex-grow: 1;
            height: 42px;
            border: 1px $light solid;
            border-radius: 5px;
            margin-right: 6px;
            flex-direction: row;
            box-sizing: border-box;

            .react-datepicker__input-container {
                position: absolute;
                top: 10px;
                right: 13px;
                width: 82px;

                input {
                    @include roboto;
                    width: 82px;
                    text-align: end;
                    font-size: 14px;
                    color: $darker-brand;
                    border: none;
                    outline: none;
                }
            }
    
            &:last-of-type {
                margin: 0;
                width: 90px;
                flex-grow: 0;

                .react-datepicker__input-container {
                    width: 74px;

                    input {
                        width: 74px;
                    }
                }
            }
        }
    }
}
