@import "../../styles/global";
@import "../../styles/font";

.modal {
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    background-color: rgba($color: black, $alpha: 0.35) !important;
    z-index: 2;
    display: flex;
    align-items: center;
    justify-content: center;
}
