@import "../../../styles/global";
@import "../../../styles/font";

.dropdown {
    p {
        @include roboto;
        font-weight: $medium;
        line-height: 20px;
        font-size: 13px;
        color: $dark;
        margin: 0 0 4px;
    }

    .dd-wrapper {
        position: relative;
        margin: 0;
        user-select: none;

        .dd-header {
            outline: none;
            overflow: hidden;
            background: $white;
            color: $darker-brand;
            border: $light 1px solid;
            border-radius: 5px;

            .dd-header-title {
                @include roboto;
                margin-left: 16px;
                margin-right: 32px;
                overflow: hidden; 
                white-space: nowrap;
                font-size: 14px;
                line-height: 42px;
                font-weight: $medium;
                text-overflow: ellipsis;
            }

            .dd-placeholder {
                color: $dark;
            }

            .dd-arrow {
                position: absolute;
                right: 12px;
                top: 50%;
                transition: .1s;
                transform: translateY(-50%);

                &.up {
                    transform: rotate(180deg);
                }
            }
            cursor: pointer;
        }

        .dd-list {
            position: absolute;
            z-index: 1;
            margin: 8px 0 0;
            padding: 5px 0;
            width: 100%;
            box-sizing: border-box;
            outline: none;
            overflow: scroll;
            background: $white;
            color: $darker-brand;
            border: $light 1px solid;
            border-radius: 5px;
            max-height: 300px;
            -ms-overflow-style: none;

            &::-webkit-scrollbar {
                display: none;
            }
              

            .dd-list-item {
                @include roboto;
                cursor: pointer;
                list-style-type: none;
                margin-left: 16px;
                margin-right: 16px;
                white-space: nowrap;
                font-size: 14px;
                line-height: 30px;
                font-weight: $regular;
                text-overflow: ellipsis;

                &:hover {
                    color: $darker;
                }
            }
        }

        &:hover {
            .dd-header {
                border: $grey 1px solid;
            }
        }

        &.dd-wrapper-open {
            .dd-header {
                border: $dark 1px solid;
            }

            .dd-list {
                border: $dark 1px solid;
            }
        }
    }
}
