@import "../../../../../styles/global";
@import "../../../../../styles/font";

.file-adder {
    position: relative;
    width: 350px;
    background-color: $white;
    border-radius: 10px;

    h1 {
        @include sans;
        font-weight: $bold;
        font-size: 14px;
        line-height: 20px;
        color: $darker-brand;
        margin: 0;
        padding: 12px 16px 10px;
        border-bottom: 1px $light solid;
    }

    .close {
        position: absolute;
        top: 16px;
        right: 16px;
        cursor: pointer;

        &:hover {
            g {
                fill: $darker;
            }
        }
    }

    .input-parent {
        margin: 0 16px;
    }

    .text-area {
        margin: 16px;
    }

    .file-upload {
        margin: 16px;
    }

    .footer {
        margin: 20px 16px !important;

        .button {
            height: 34px;
            min-width: 87px;
            width: auto;
            padding: 0 10px;
        }
    }

    .file-holder {
        padding: 0 16px;

        .file-container {
            max-height: 200px;
            overflow: scroll;
        }

        p {
            @include roboto;
            line-height: 20px;
            font-size: 13px;
            color: $dark;
            margin: 0 0 4px;
            height: 20px;

        }
        .file-view {
            display: flex;
            position: relative;
            height: 28px;
            text-overflow: ellipsis;
            word-break: break-all;
            word-wrap: break-word;
            p {
                margin: 0;
                color: $darker;
                line-height: 28px;
                font-size: 13px;
                &:first-of-type {
                    display: block;
                    width: 160px;
                    margin-right: 8px;
                    height: 28px;

                }
            }
            svg {
                position: absolute;
                top: 9px;
                right: 0;
                width: 8px;
                height: 8px;
                cursor: pointer;
        
                &:hover {
                    g {
                        fill: $darker;
                    }
                }
            }

            .progress-bar {
                position: absolute;
                top: 11px;
                right: 0;
            }
        }
    }
}
