@import "../../../../styles/global";
@import "../../../../styles/font";

.info-box {
    position: relative;
    height: 98px;
    flex-grow: 1;
    border: 1px solid $grey;
    border-radius: 10px;

    h1 {
        @include sans;    
        position: absolute;
        font-size: 14px;
        line-height: 17px;
        font-weight: $bold;
        color: $dark;
        bottom: 18px;
        left: 17px;
        text-transform: uppercase;
        margin: 0;
    }

    .info-box-info {
        @include roboto;    
        position: absolute;
        top: 16px;
        left: 17px;
        
        p {
            display: inline;
            font-size: 20px;
            line-height: 26px;
            font-weight: $regular;
            color: $darker-brand;
            margin: 0;
        }

        .info-box-extra {
            @include roboto;    
            display: inline;
            font-size: 14px;
            line-height: 16px;
            font-weight: $regular;
            color: $dark;
            margin: 0;
            margin-inline-start: 5px;
        }
    }
}
