@import "../../../styles/global";
@import "../../../styles/font";

.side-menu {
    // position: relative;

    div {
        position: relative;

        .dd-wrapper {
            display: block;
            margin: 23px 20px auto 20px;
        }

        .seperator {
            background-color: rgba($light, 0.5);
            height: 1px;
            margin: 16px 20px;
        }
    }

    ul {
        position: absolute;
        margin: 0;
        padding: 0;
    }
}
