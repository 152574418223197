@import "../../../../../styles/global";
@import "../../../../../styles/font";

.release-adder {
    position: relative;
    width: 350px;
    background-color: $white;
    border-radius: 10px;

    h1 {
        @include sans;
        font-weight: $bold;
        font-size: 14px;
        line-height: 20px;
        color: $darker-brand;
        margin: 0;
        padding: 12px 16px 10px;
        border-bottom: 1px $light solid;
    }

    .release-holder {
        padding: 16px 16px 0;

        .dropdown {
            width: 100%;
        }

        .datetime-picker {
            margin: 0 0 9px;
            width: 100%;

            .datetime-holder {
                width: 100%;
            }
        }
    }

    .close {
        position: absolute;
        top: 16px;
        right: 16px;
        cursor: pointer;

        &:hover {
            g {
                fill: $darker;
            }
        }
    }

    .footer {
        margin: 20px 16px !important;

        .button {
            height: 34px;
            width: 87px;
        }
    }
}
