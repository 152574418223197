@import "../../../styles/global";
@import "../../../styles/font";

.devices {

    .devices-top-bar {
        display: flex;
        margin-bottom: 16px;

        .device-actions {
            min-width: 108px;
            flex-grow: 0;
        }

        .device-search {
            flex-grow: 1;
            // margin-left: 10px;
        }

        .device-add-button {
            max-width: 155px;
            flex-grow: 0;
        }
    }

    .pagination {
        margin-top: 20px;
    }
    
    .no-device-text {
        @include roboto;
        margin-top: 32px;
        width: 100%;
        text-align: center;
        line-height: 45px;
        font-size: 14px;
        color: $dark;
        font-weight: $regular;
    }

    .tab-header {
        display: flex;

        h1 {
            @include sans;
            font-size: 24px;
            line-height: 32px;
            font-weight: $bold;
            color: $darker-brand;
            margin-top: 0px;
            margin-bottom: 22px;
        }

        .number-dropdown {
            width: 80px;
            height: 32px;
            margin-left: 12px;

            .dd-header {
                .dd-header-title {
                    line-height: 32px;
                }
            }
        }
    }

}
