@import "../../../styles/global";
@import "../../../styles/font";

.content-builds {

    .tab-header {
        display: flex;

        h1 {
            @include sans;
            font-size: 24px;
            line-height: 32px;
            font-weight: $bold;
            color: $darker-brand;
            margin-top: 0px;
            margin-bottom: 22px;
        }
    }

    .content-builds-top-bar {
        display: flex;
        margin-bottom: 16px;

        .content-builds-search {
            flex-grow: 1;
            margin-right: 10px;
        }

        .content-builds-add-button {
            max-width: 200px;
            flex-grow: 0;
        }
    }

    .table {
        &:first-of-type {
            h6 {
                margin-top: 6px;
            }
        }
        h6 {
            margin-top: 22px;
        }
    }

    .pagination {
        margin-top: 20px;
    }
    
    .no-content-builds-text {
        @include roboto;
        margin-top: 32px;
        width: 100%;
        text-align: center;
        line-height: 45px;
        font-size: 14px;
        color: $dark;
        font-weight: $regular;
    }

    .loader-button{
        background-color: unset;
        position: absolute;
    }
}
