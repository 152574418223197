@import "../../../../styles/global";
@import "../../../../styles/font";

.content-build-editor {
    display: grid;

    grid-template-columns: 1fr 1fr;
    grid-template-areas: 'editor extra';

    .editor {

        h6 {
            @include roboto;
            font-weight: $medium;
            font-size: 13px;
            line-height: 20px;
            color: $dark;
            margin: 0 0 4px;
        }

        .datetime-picker-holder {
            margin: 0 0 16px;
        }

        .tab-header {
            display: flex;
            margin-bottom: 26px;

            h1 {
                @include sans;

                font-size: 24px;
                line-height: 32px;
                font-weight: $bold;
                color: $darker-brand;
                margin: auto;
                flex-grow: 1;
            }

            .button {
                flex-grow: 0;
                width: auto;
                min-width: 88px;
                padding: 0 12px;
                height: 34px;
                margin-left: 10px;
            }
        }

        .filesets {
            margin: 0 0 12px; 
            background-color: rgba($lightest, 0.5);
            border: $light 1px  solid;
            padding: 12px;

            .table {
                margin: 0;
    
                &.small-table {
                    h6 {
                        color: $darker;
                    }
                }
            }
        }

        .react-datepicker-wrapper {
            input {
                // background-color: black;
            }
        }
        
        .input {
            margin: 0 0 16px;
        }

        .dropdown {
            width: 250px;
        }

        .table {
            margin: 0;

            & +.table {
                margin-top: 12px;
            }
        }
        
        .footer {
            display: flex;
            margin: 22px 0;

            div {
                flex-grow: 1;
            }

            .button {
                flex-grow: 0;
                height: 34px;
                width: auto;
                padding: 0 12px;
                min-width: 88px;
                margin-left: 10px;
            }
        }
    }
}
