@import "../../../styles/global";
@import "../../../styles/font";

.notification {
    position: relative;
    width: 300px;
    background-color: $white;
    border-radius: 10px;

    h1 {
        @include roboto;
        font-weight: $medium;
        font-size: 14px;
        line-height: 24px;
        color: $darker-brand;
        margin: 18px 32px 0px;
        text-align: center;
    }

    p {
        @include roboto;
        font-weight: $regular;
        font-size: 14px;
        line-height: 24px;
        color: $darker-brand;
        margin: 0 32px;
        text-align: center;
    }

    .footer {
        display: flex;
        margin: 22px 0;

        div {
            flex-grow: 1;
        }

        .button {
            margin: 0 5px;
            height: 34px;
            width: 87px;
        }
    }
}
