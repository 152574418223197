@import '../../../styles/global';
@import '../../../styles/font';

.file-upload {
    position: relative;

    .drop-box {
        width: 100%;
        height: 112px;
        box-sizing: border-box;
        background-color: $lightest;
        border: 2px $light dashed;
        outline: none;

        &.drop-box-drag {
            border: 2px $darker dashed;
        }

        p {
            @include roboto;
            position: absolute;
            top: 50%;
            left: 17%;
            width: 66%;
            font-size: 14px;
            line-height: 24px;
            font-weight: $regular;
            color: $dark;
            text-align: center;
            margin: 0;
            transform: translateY(-50%);
            user-select: none;
            text-overflow: ellipsis;
            word-break: break-all;
            pointer-events: none;

            .link {
                cursor: pointer;
                color: $brand;
                display: inline;

                &:hover {
                    text-decoration: underline;
                }
                
                &:active {
                    color: $darker-brand;
                }
            }
        }
    }

    input {
        position: absolute;
        width: 100%;
        height: 100%;
        opacity: 0;
        left: 0;
        top: 0;
    }
}
