@import "../../../styles/global";
@import "../../../styles/font";

.progress-bar {
    width: 74px;
    height: 4px;
    .progress-holder {
        position: relative;

        div {
            position: absolute;
            left: 0;
            top: 0;

            height: 4px;
            &:first-of-type {
                width: 74px;
                background-color: $light;
            }
            &:last-of-type {
                background-color: $brand;
            }
        }
    }
}
