@import '../../../styles/global';
@import '../../../styles/font';

.pagination {
    @include roboto;
    position: relative;
    width: 100%;
    height: 34px;

    p {
        position: absolute;
        top: 9px;
        font-size: 14px;
        font-weight: $medium;
        line-height: 16px;
        color: $dark;
        margin: 0;
    }

    .pagination-holder {
        position: absolute;
        left: 50%;
        transform: translateX(-50%);

        .button {
            height: 32px;
            width: 32px;
            margin: 0;
            transition: none;

            &:first-of-type {
                width: 88px;
                margin-right: 20px;
            }

            &:last-of-type {
                width: 88px;
                margin-left: 20px;
            }
        }
    }
}
